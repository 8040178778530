import React from 'react';
import {
  Added,
  Link,
  List,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="September 2018"
      subnav="release-notes">
      <div id="September2018" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          Everyone’s favorite month* just got a whole lot cooler. Not only are
          punctuation guidelines now a thing, but we added two new components to
          help you provide the right kind of FYI. Whether the workflow requires
          a decision or would simply benefit from added context, alerts and
          notes are here to help. For more on those—plus a quick update from
          space—you’ll have to scroll.
        </Paragraph>
        <Paragraph>
          <em>*100% made up</em>
        </Paragraph>

        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Added>
                Rules re:{' '}
                <Link href="/components/dialogs/alert" isDesignCodeLink>
                  alerts
                </Link>
                . Interrupt wisely.
              </Added>
              <Added>
                Guidelines for the less aggressive{' '}
                <Link href="/components/notifications/note" isDesignCodeLink>
                  note
                </Link>
                .
              </Added>
              <Added>
                Punctuation{' '}
                <Link href="http://hudl.com/design/writing/grammar/punctuation">
                  dos and don’ts
                </Link>
                .
              </Added>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="3.20.1"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v3.20.1"
            />

            <div className={styles.changesList}>
              <Added>
                <Link href="/components/dialogs/alert" isDesignCodeLink>
                  The alert
                </Link>
                . It’s like a modal, but bossier.
              </Added>
              <Added>
                A{' '}
                <Link href="/components/notifications/note" isDesignCodeLink>
                  little something
                </Link>{' '}
                to the notification family.
              </Added>
              <Added>
                A <code>className</code> prop to a number of form fields:
                <List>
                  <li>Input</li>
                  <li>Text Area</li>
                  <li>Radio</li>
                  <li>Checkbox</li>
                </List>
              </Added>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader libName="Sketch Library" />

            <div className={styles.changesList}>
              <Added>
                One{' '}
                <Link href="/components/dialogs/alert" isDesignCodeLink>
                  new component
                </Link>
                . Interrupt wisely.
              </Added>
              <Added>
                The other{' '}
                <Link href="/components/notifications/note" isDesignCodeLink>
                  new component
                </Link>
                .
              </Added>
              <Added>Space blocks to help with sizing. 🚀</Added>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
